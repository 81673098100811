import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideNotifications } from "./store/actionCreator";

function Notifications() {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const handleClose = () => {
    dispatch(hideNotifications());
  };

  return (
    <Snackbar
      open={notifications.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={notifications.type}
        sx={{
          width: "100%",
          backgroundColor:
            notifications.type === "error" ? "rgb(211, 47, 47)" : "#2e7d32",
          color: "#fff",
          "& svg": {
            color: "#fff",
          },
        }}
      >
        {notifications.message}
      </Alert>
    </Snackbar>
  );
}

export default Notifications;
