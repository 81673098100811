import { combineReducers } from "redux";
import notifications from "../layouts/notifications/store/reducer";
import auth from "../modules/auth/store/reducer";
import settings from "../modules/admin/settings/store/reducer";

const reducers = combineReducers({
  auth,
  notifications,
  settings,
});

export default reducers;
