import React, { lazy, Suspense } from "react";
import Loader from "../layouts/Loader";
import { Redirect, Route } from "react-router-dom";
import Default from "../layouts/components/Default";
import Notifications from "../layouts/notifications/Notifications";
import { isAuth } from "../utils/helpers";

function Private(props) {
  const { component, ...rest } = props;
  const Component = lazy(() => import(`../${component}`));

  if (!isAuth()) {
    return <Route {...rest} render={() => <Redirect to="/" />} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<Loader />}>
          <Notifications />
          <Default>
            <Component {...props} />
          </Default>
        </Suspense>
      )}
    />
  );
}

export default Private;
