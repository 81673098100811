import { SET_SETTINGS } from "./actionTypes";

const initialState = {};

const settingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SETTINGS:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default settingReducer;
