import ReeValidate from "ree-validate";

export const Validator = (fields) => {
  const validator = new ReeValidate(fields);

  const dictionary = {
    en: {
      messages: {
        required: () => "This field is required",
      },
    },
  };

  validator.localize(dictionary);

  return validator;
};

export const handleErrorResponse = (err) => {
  return (
    (err && err.response && err.response.data && err.response.data.message) ||
    err.message
  );
};

export const isAuth = () => {
  return !!localStorage.getItem("accessToken");
};

export const isJudgeAuth = () => {
  return !!localStorage.getItem("judgeToken");
};

export const userTypes = [
  {
    value: "1",
    label: "Tech",
  },
  {
    value: "2",
    label: "Judges",
  },
  {
    value: "3",
    label: "Host",
  },
];

export const getRoles = (key) => {
  return ["Admin", "Tech", "Judges", "Host"][parseInt(key)];
};

export const calculationType = [
  {
    name: "weighted_score",
    label: "Weighted Score",
  },
  {
    name: "score",
    label: "Average Score",
  },
];

export const rankSort = [
  {
    name: "asc",
    label: "Ascending",
  },
  {
    name: "desc",
    label: "Descending",
  },
];

export const rankColumn = [
  {
    name: "ranking",
    label: "By Rank",
  },
  {
    name: "score",
    label: "By Score",
  },
];

export const chunkArray = (arr, len) => {
  var chunks = [],
    i = 0,
    n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
};

export const shuffle = (arr = []) => {
  return arr
    .map((value) => ({ item: value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ item }) => item);
};

export const resultFieldsOptions = [
  {
    name: "score",
    label: "Avg Score",
  },
  {
    name: "total_score",
    label: "Total Score",
  },
  {
    name: "avg_ranking",
    label: "Avg Ranking",
  },
  {
    name: "total_ranking",
    label: "Total Ranking",
  },
  {
    name: "ranking",
    label: "By Ranking",
  },
];

export const sortOptions = [
  {
    name: "asc",
    label: "Ascending",
  },
  {
    name: "desc",
    label: "Descending",
  },
];
